<!-- 编辑弹窗 -->
<template>
  <a-modal
    :width="680"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改公告' : '新建公告'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="customerForm"
      :rules="customerRules"
      :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="公告标题" name="noticeTitle">
            <a-input
              placeholder="请输入公告标题"
              v-model:value="customerForm.noticeTitle"
              allow-clear
            ></a-input>
          </a-form-item>
          <a-form-item label="公告状态" name="noticeState">
            <a-select
              v-model:value="customerForm.noticeState"
              placeholder="请选择公告状态"
            >
              <a-select-option value="0">创建</a-select-option>
              <a-select-option value="0">上线</a-select-option>
              <a-select-option value="0">下线</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="所属运营商" name="operator">
            <a-select
              v-model:value="customerForm.operator"
              placeholder="请选择所属运营商"
            >
              <a-select-option value="0">厦门远丞科技有限公司</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
// import * as batteryModelApi from '@/api/rnt/batteryModel'
// import * as operatorApi from '@/api/rnt/operator'
export default {
  name: 'batteryModelEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      // 表单数据
      customerForm: Object.assign({}, this.data, { model: '' }),
      // 编辑弹窗表单验证规则
      customerRules: {
        noticeTitle: [
          {
            required: true,
            message: '请输入公告标题',
            trigger: 'blur',
            type: 'string'
          }
        ],
        noticeState: [
          {
            required: true,
            message: '请选择公告状态',
            trigger: 'blur',
            type: 'string'
          }
        ],
        operator: [
          {
            required: true,
            message: '请选择所属运营商',
            trigger: 'blur',
            type: 'string'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 项目列表
      modules: [],
      // 电池品牌
      brandList: [],
      // 型号
      modelList: [],
      // 运营商名称
      operatorList: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        console.log('data', this.data)
        this.customerForm = this.data
        this.isUpdate = true
      } else {
        console.log('data', this.data)
        this.customerForm = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  mounted() {
    // this.queryOperatorList()
    // this.getBrandList()
    // this.getModelList()
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          //   this.loading = true
          //   this.form.createUserId = this.$store.state.user.user.userId
          //   batteryModelApi
          //     .save(this.form, this.isUpdate)
          //     .then((res) => {
          //       this.loading = false
          //       if (res.code === 0) {
          //         this.$message.success(res.msg)
          //         if (!this.isUpdate) {
          //           this.form = {}
          //         }
          //         this.updateVisible(false)
          //         this.$emit('done')
          //       } else {
          //         this.$message.error(res.msg)
          //       }
          //     })
          //     .catch((e) => {
          //       this.loading = false
          //       this.$message.error(e.message)
          //     })
        })
        .catch(() => {})
    },
    /* 查询运营商 */
    // queryOperatorList() {
    //   operatorApi
    //     .page()
    //     .then((res) => {
    //       if (res.code === 0) {
    //         this.operatorList = res.data
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       this.$message.error(e.message)
    //     })
    // },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>
<style scoped lang='less'>
.inputNumber {
  width: 100%;
}
</style>
